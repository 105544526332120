<template>
  <div class="yimiao">
    <div>
      <div style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        ">
        <div style="display: inline-block; width: 60px">
          <img style="width: 100%"
            src="../../assets/xuqiu.png"
            alt="" />
        </div>
        <div style="display: inline-block; position: absolute; top: 30px;left:100px">
          <div style="font-size: 18px">管控人员报备</div>
        </div>
      </div>
      <div>
        <van-cell-group>
          <van-field v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区(村)"
            placeholder="请选择社区(村)" />
          <van-popup v-model="idListOrg"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="所住小区(组)"
            placeholder="请选择您所住小区(组)" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.RoomCode"
            label="房号(单位)"
            placeholder="请输入房号(单位)" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Name"
            required
            label=" 姓 名"
            placeholder="请输入姓名" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.IDCard"
            label="身份证号"
            required
            placeholder="请输入身份证号码" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.DivideTime"
            label="隔离时间"
            @click="datafromDivideTime = true"
            readonly="readonly"
            required
            placeholder="请选择隔离时间" />
          <van-popup v-model="datafromDivideTime"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="datetime"
              title="请选择隔离时间"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromDivideTime = false"
              @confirm="ondatafromDivideTime">
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.RelieveTime"
            label="解除时间"
            @click="datafromRelieveTime = true"
            readonly="readonly"
            required
            placeholder="请选择解除时间" />
          <van-popup v-model="datafromRelieveTime"
            position="bottom"
            get-container="body">
            <van-datetime-picker show-toolbar
              type="datetime"
              title="请选择解除时间"
              :min-date="minDateJc"
              :max-date="maxDate"
              @cancel="datafromRelieveTime = false"
              @confirm="ondatafromRelieveTime">
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="datafrom.Address"
            required
            label="住 址"
            placeholder="请输入住址" />
        </van-cell-group>
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px">*</span>
          <span style="color: #646566;font-size: 14px;">健康码类型</span>
          <div style="margin-left: 7%;display: inline-block;">
            <van-radio-group v-model="datafrom.HCType"
              style="line-height:20px;"
              direction="horizontal">
              <van-radio name="1">绿码</van-radio>
              <van-radio name="2">黄码</van-radio>
              <van-radio name="3">红码</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="submit"
          style="padding:10px 7px;">
          <span style="color: red; font-size: 14px; letter-spacing: 8px;margin: 0px 7px;"></span>
          <span style="color: #646566;font-size: 14px; ">隔离点</span>
          <div style="margin-left: 28%;display: inline-block;">
            <van-radio-group v-model="datafrom.Location"
              direction="horizontal">
              <van-radio name="1"
                style="margin-bottom:5px;">居家观察</van-radio>
              <van-radio name="2"
                style="margin-bottom:5px;">街道隔离</van-radio>
              <van-radio name="3"
                style="margin-bottom:5px;">县级隔离</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-cell-group>
          <van-field v-model="datafrom.Remark"
            label="备 注"
            placeholder="请输入备注" />
        </van-cell-group>

      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info"
          style="margin:10px 0;"
          @click="postfrom()">提交</van-button>
        <van-button style="border: 1px solid #e6e6e6;margin:10px 0;"
          type="
          default"
          @click="fanhui()">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  WxSaveIsolation,
  WeGetAppletOrganList,
  GetOrgansNullToken
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data () {
    return {
      // 健康码
      jkImageList: [],
      //行程码
      xcImageList: [],
      //核酸证明
      hsImageList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      OrganList: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      minDateJc: new Date(2021, 0),
      NativeCantonCodedata: [],
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        IId: 0,  //id、
        OrgCode: "",  // 社区(村)编码、
        RId: "",  // 小区(组)id、
        RoomCode: "",  // 房号、
        Name: "",  // 姓名、 
        Mobile: "",  // 电话、
        IDCard: "",  // 身份证号、
        Address: "",  // 住址、
        HCType: "",  // 健康码类型、
        Location: "",  // 隔离点、
        Remark: "",  // 备注、 
      },
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromRelieveTime: false,
      datafromDivideTime: false,
    };
  },
  created () {
    this.GetOrgansNullToken();
  },
  methods: {
    //上传健康码
    afterRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.HealthCode = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除健康码
    jkDelete (file) {
      this.jkImageList = []
      this.datafrom.HealthCode = ""
    },
    //上传行程码
    afterXcRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.TravelCode = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除行程码
    xcDelete (file) {
      this.xcImageList = []
      this.datafrom.TravelCode = ""
    },
    //上传核酸证明
    afterhsRead (file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success (result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios
            .post(config.apiUploadUrl, formData, c)
            .then((res) => {
              if (res.data.code === 0) {
                console.log(res.data.data);
                that.datafrom.Testify = res.data.data
                // that.datafrom.EvalImgs.push(res.data.data);
                file.status = "";
                file.message = "";
              } else {
                Toast.fail(res.data.msg);
              }
            })
        },
      });
    },
    //删除核酸
    hsDelete (file) {
      this.hsImageList = []
      this.datafrom.Testify = ""
    },
    // 提交
    postfrom: function () {
      console.log(this.datafrom);
      //身份证号验证
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      // 手机号验证
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.datafrom.OrgCode == "") {
        Toast.fail("请选择社区(村)");
        return false;
      }
      if (this.datafrom.RId == "") {
        Toast.fail("请选择小区(组)");
        return false;
      }
      if (this.datafrom.Name == "") {
        Toast.fail("请输入姓名");
        return false;
      }
      // if (this.datafrom.RoomCode == "") {
      //   Toast.fail("请输入房号");
      //   return false;
      // }

      if (this.datafrom.IDCard == "") {
        Toast.fail("请输入身份证号码");
        return false;
      } else {
        if (!reg.test(this.datafrom.IDCard)) {
          Toast.fail("身份证号码不正确");
          return false;
        }
      }
      if (this.datafrom.Mobile == "") {
        Toast.fail("请输入联系电话");
        return false;
      } else {
        if (!reg_tel.test(this.datafrom.Mobile)) {
          Toast.fail("请输入正确的联系电话");
          return false;
        }
      }

      if (this.datafrom.Address == "") {
        Toast.fail("请输入住址");
        return false;
      }
      if (this.datafrom.HCType == "") {
        Toast.fail("选择健康码类型");
        return false;
      }
      // if (this.datafrom.Location == "") {
      //   Toast.fail("请选择隔离点");
      //   return false;
      // }
      this.datafrom.OpenID = getOpenId();
      WxSaveIsolation(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            // this.$router.push({
            //   name: "register",
            // });
            this.$router.go(-1)
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!" + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    gitocodelist () {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      })
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.push({
        name: "register",
      });
    },
    // 社区选择器
    onidListOrg (val) {
      this.datafrom.OrgCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId (val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow (val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },
    // 疫苗选择器
    onyimiao (val) {
      this.datafrom.yimiao = val.id;
      this.datafrom.yimiaoName = val.Name;
      this.yimiao = false;
    },
    // 隔离时间
    ondatafromDivideTime (val) {
      console.log(val);
      this.datafrom.DivideTime = this.dateformat(val);
      this.datafromDivideTime = false;
      this.minDateJc = new Date(this.datafrom.DivideTime)
      this.datafrom.RelieveTime = '';
    },
    // 解除时间
    ondatafromRelieveTime (val) {
      this.datafrom.RelieveTime = this.dateformat(val);
      if (this.datafrom.DivideTime > this.datafrom.RelieveTime) {
        Toast.fail('解除时间不能早于隔离时间')
        return
      } else {
        this.datafrom.RelieveTime = this.dateformat(val);
      }

      this.datafromRelieveTime = false;
    },

    // 社区
    onocode (val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat (val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>